import React, { useState } from "react";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle,  TableRow, Table, FormControl,
    TableHead, TableBody, InputLabel, Select, MenuItem, TableCell} from "@material-ui/core";
import * as Yup from 'yup';
import { Grid,  TextField } from "@material-ui/core";
import { useFormik } from 'formik';
import {  useEffect } from 'react';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import Widget from "../../components/Widget/Widget";
import PageTitle from "../../components/PageTitle/PageTitle";
import { withStyles, makeStyles } from '@material-ui/core/styles';
import AdvatagementService from "./Locality/Service/advatagementService";
import CoachingService from "./Locality/Service/coachingService";
import ParkService from "../dashboard/Locality/Service/parkService";

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: "rgb(255, 107, 129)",
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);
const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 200,
    },
}));

export default function Advatagement() {
    const tableHeaders = ['Image','Name', 'Description', 'URL','Park Name ', 'Start Date', 'end Date',
    'Edit', 'Delete', ];
    const classes = useStyles();
    const [doctorList, setDoctorList] = useState([]);
    const [age, setAge] = React.useState('');
    const [file, setFile] = useState(null);
    const [imageUrl, setImageUrl] = useState([]);
     const [doctorIdList, setDoctorIdList] = useState([]);
    const [open, setOpen] = React.useState(false);
    const [advatagementList, setAdvatagementList] = useState([]);
    const [advatagement, setAdvatagement] = useState({
        advatagementName:'', 
        imgURL:'',
        parkId:'',
        startDate:'',
        endDate:'',
        urlLink:'',
        desc:''
    })
    const [parkList, setParkList] = useState([]);
    const [parkId, setParkId] = useState("");
    const [parkName, setParkName] = useState("");  
    const validationSchema = Yup.object().shape({
        advatagementName: Yup.string(),
        parkId:Yup.string().required('park name is required'),
        urlLink:Yup.string(),
        desc:Yup.string(),
        startDate: Yup.string(),
        endDate:Yup.string(),
    });
  
    useEffect(() => {
        getAdvatagementList();
        // getSpecilizationList();
        // getProfileId();
        getParkList();
        return () => {
            // setDoctorIdList([]);
            setAdvatagementList([]);
            // setSpecilizationList([]);
            setParkList([]);
        }
    }, []);
    const handleOpen = (status) => {
        if(status){
            setAdvatagement({
                advatagementName:'', 
                parkId:'',
                startDate:'',
                endDate:'',
                urlLink:'',
                desc:''
        })
        }
     
          setOpen(true);
      };
    const handleClose = () => {
        setOpen(false);
    };
    const dateAndTime = (date) => {

        const systemDate = new Date(date);
        const formattedDateTime = systemDate.toLocaleString('en-US', {
            year: 'numeric',
            month: 'long',
            day: 'numeric',

        });
        return formattedDateTime;
    }
    const getParkList = () => {
        ParkService.getAllPark().then((res) => {
            setParkList(res);

        }).catch((err) => {
            // setError(err.message);
        });
    }
    const editAdvatagement = (advatagement) => {
        
       const obj = JSON.parse(JSON.stringify(advatagement));
        obj.parkId = advatagement.parkId ? advatagement.parkId._id : '';
        // obj.specilizationId = doctor.specilizationId ? doctor.specilizationId._id : '';
        //advatagement.parkId = advatagement.parkId ? advatagement.parkId._id :'';
       
      
        setAdvatagement(obj);
        handleOpen(false);
        
    }
    const deleteAdvatagement = (advatagementdelete) => {
        if (advatagementdelete) {
            AdvatagementService.deleteAdvatagement(advatagementdelete).then((res) => {
                getAdvatagementList();
                // getProfileId();
            }).catch((err) => {
            });
        }
    };
    const handleInputChange = (index,event) => {
        const { name, value } = event.target;
        
        
        
      };
      const handleFileChange = (event) => {
        setFile(event.target.files[0]);
    };
   
    const uploadImage = async (event,index) => {
    const file = event.target.files[0];
       if (!file) {
           alert("Please select image to upload");
           return;
       }
       const formData = new FormData();
       formData.append('image', file);
       formData.append('imageName', file.name);
       try {
           const response = await CoachingService.fileUpload( formData);


        //    handleInputChange(index,{target:{name:"imageUrl",value:response.url}},);
          // setImageUrl(response.url)
           setImageUrl((prevImages) => [...prevImages, response.url]);




       } catch (error) {
           console.error(error);
       }
   };

const getAdvatagementList = () => {
    AdvatagementService.getAllAdvatagement().then((res) => {
        setAdvatagementList(res);
    }).catch((err) => {
        // setError(err.message);
    });
}
    const formik = useFormik({
        initialValues: advatagement,
        enableReinitialize: true,
        validationSchema: validationSchema,
        onSubmit: (values, { resetForm }) => {
            const userDetails = JSON.parse(localStorage.getItem("userDetail"));
           
            if (advatagement._id) {
                values.imgURL = imageUrl.length ? imageUrl : advatagement.imgURL;
                AdvatagementService.upadeAdvatagement(values).then((res) => {
                    
                    handleClose();
                    // getProfileId();
                    getAdvatagementList();
                    resetForm()
                    alert("Advatagement Updated Successfully.");
                }).catch((err) => {
                });
            }
            else {
                values.imgURL = imageUrl;
                AdvatagementService.creteAdvatagement(values).then((res) => {
                   getAdvatagementList();
                    // getProfileId();
                    resetForm();
                    handleClose();
                    alert(" advertisement Added Successfully.");
                    // props.history.push('/app/vendor');
                })
                    .catch((err) => {
                        alert(err.response.data.message)
                    })
            }

        },
    });

    return (

        <>
            <PageTitle title="Advertisement" button={<Button
                variant="contained" onClick={() => handleOpen(true)}
                size="medium"
                color="secondary" style={{ backgroundColor: '#ff6b81' }}> Add advertisement
            </Button>} />
            <Grid container spacing={4}>
                <Grid item xs={12}>
                    <Widget title="" upperTitle noBodyPadding bodyClass={classes.tableOverflow}>

                        <Table className="mb-0">
                            <TableHead >
                                <TableRow>
                                    {tableHeaders.map(key => (
                                        <StyledTableCell key={key}>{key}</StyledTableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {advatagementList.map((advatagement) => (
                                    <TableRow key={advatagement._id}>
                                           <TableCell className="pl-3 fw-normal" >{advatagement.imgURL ? <img  height={50} width={50} src={advatagement.imgURL[0]}/>:''}</TableCell>
                                          <TableCell className="pl-3 fw-normal" >{advatagement.advatagementName}</TableCell>
                                          <TableCell className="pl-3 fw-normal" >{advatagement.desc}</TableCell>
                                          <TableCell className="pl-3 fw-normal" >{advatagement.urlLink}</TableCell>

                                        <TableCell className="pl-3 fw-normal" >{advatagement.parkId?.parkName}</TableCell>
                                        <TableCell className="pl-3 fw-normal" >{advatagement.startDate}</TableCell>
                                        <TableCell className="pl-3 fw-normal" >{advatagement.endDate}</TableCell>
                                        {/* <TableCell className="pl-3 fw-normal" >{doctor.fee}</TableCell> */}

                                        <TableCell>
                                            <EditIcon style={{ cursor: 'pointer' }} onClick={() => editAdvatagement(advatagement)} >
                                            </EditIcon >
                                        </TableCell>
                                        <TableCell>
                                            <DeleteIcon style={{ cursor: 'pointer' }} onClick={() => deleteAdvatagement(advatagement)} />
                                        </TableCell>
                                        
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </Widget>
                </Grid>
            </Grid>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Add Advatagement</DialogTitle>
                <form onSubmit={formik.handleSubmit} >
                    <DialogContent style= {{ width: 308 }}>
                        {/* <FormControl variant="standard" fullWidth="true" >
                            <InputLabel id="demo-simple-select-standard-label">Age</InputLabel>
                            <Select
                                labelId="demo-simple-select-standard-label"
                                id="className"
                                name="classId"
                                label="className"
                                value={formik.values.classId}
                                onChange={formik.handleChange}
                                error={formik.touched.classId && Boolean(formik.errors.classId)}
                                helperText={formik.touched.classId && formik.errors.classId}
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                {addClassList.map(({ _id, className }) => (
                                    <MenuItem key={_id} value={_id}>{className}
                                      
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl> */}
                        <TextField
                            InputProps={{ style: { width: 258} }}
                            autoFocus
                            margin="dense"
                            id="advatagementName"
                            name="advatagementName"
                            label="Advertisement Name"
                            type="text"
                            variant="standard"
                            value={formik.values.advatagementName}
                            onChange={formik.handleChange}
                            error={formik.touched.advatagementName && Boolean(formik.errors.advatagementName)}
                            helperText={formik.touched.advatagementName && formik.errors.advatagementName}
                        />
                        <br></br>
                        <br></br>
                        <TextField
                            InputProps={{ style: { width: 258} }}
                            autoFocus
                            margin="dense"
                            id="desc"
                            name="desc"
                            label="description"
                            type="text"
                            variant="standard"
                            value={formik.values.desc}
                            onChange={formik.handleChange}
                            error={formik.touched.desc && Boolean(formik.errors.desc)}
                            helperText={formik.touched.desc && formik.errors.desc}
                        />
                        <br></br>
                        <TextField
                            InputProps={{ style: { width: 258} }}
                            autoFocus
                            margin="dense"
                            id="urlLink"
                            name="urlLink"
                            label="URL"
                            type="text"
                            variant="standard"
                            value={formik.values.urlLink}
                            onChange={formik.handleChange}
                            error={formik.touched.urlLink && Boolean(formik.errors.urlLink)}
                            helperText={formik.touched.urlLink && formik.errors.urlLink}
                        />
                        <br></br>
                        <br></br>
 <input 
        accept="image/*"
        className={classes.input}
        id="contained-button-file"
        multiple
        type="file"
        
         onChange={uploadImage}
      />
       <br></br> <br></br>
      
      <FormControl className={classes.formControl} fullWidth="true"
                                    >
                                        <InputLabel id="demo-simple-select-label">Select Park</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            name="parkId"
                                            label="Select Park"
                                            value={formik.values.parkId}
                            onChange={formik.handleChange}

                                        error={formik.touched.parkId && Boolean(formik.errors.parkId)}
                                        helperText={formik.touched.parkId && formik.errors.parkId}
                                        >
                                            <MenuItem value="">
                                                <em>None</em>
                                            </MenuItem>

                                            {parkList.map(({ _id, parkName }) => (
                                                <MenuItem key={_id} value={_id}>{parkName}

                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                        <TextField InputProps={{ style: { width: 250 } }}
                                            id="startDate"
                                            name="startDate"
                                            label="Start Date"
                                            type="date"
                                            
                                            value={formik.values.startDate}
                                            onChange={formik.handleChange}
                                            error={formik.touched.startDate && Boolean(formik.errors.startDate)}
                                            helperText={formik.touched.startDate && formik.errors.startDate}
                                            className={classes.textField}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                          <TextField InputProps={{ style: { width: 250 } }}
                                            id="endDate"
                                            name="endDate"
                                            label="End Date"
                                            type="date"
                                            
                                            value={formik.values.endDate}
                                            onChange={formik.handleChange}
                                            error={formik.touched.endDate && Boolean(formik.errors.endDate)}
                                            helperText={formik.touched.endDate && formik.errors.endDate}
                                            className={classes.textField}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
     
                        
                    </DialogContent>

                    <DialogActions>
                        <Button onClick={handleClose}>Cancel</Button>
                        <Button type="submit">Add</Button>
                    </DialogActions>
                </form>
            </Dialog>
        </>
    );
}


