import React, { useState } from "react";
import {
    Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, InputLabel, MenuItem,
    TableRow, Table, List, ListItem, Checkbox, FormControlLabel,
    TableHead,
    TableBody, Card,
    TableCell,
    FormLabel, FormGroup, FormHelperText
} from "@material-ui/core";
import ParkService from "./Locality/Service/parkService";
import * as Yup from 'yup';
import ConfigurationService from "./Locality/Service/configurationService";
import { Typography } from "../../components/Wrappers/Wrappers";
import TablePagination from '@material-ui/core/TablePagination';
import { Grid, Select, TextField } from "@material-ui/core";
import { useFormik } from 'formik';
import { useEffect } from 'react';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import Widget from "../../components/Widget/Widget";
import PageTitle from "../../components/PageTitle/PageTitle";
import { withStyles, makeStyles } from '@material-ui/core/styles';
const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: "rgb(255, 107, 129)",
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);
const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 200,
    },
}));
export default function Configuration() {
    const tableHeaders = ['Park  Name','MID', 'Accesskey', 'WorkingKey'];
    const classes = useStyles();
    const [parkList, setParkList] = useState([]);
    const [parkId, setParkId] = useState("");
    const [parkName, setParkName] = useState("");
    const [configurationList, setConfigurationList] = useState([]);
    const [getParksList, setParks] = useState([]);
    const [qrCode, setQrCode] = useState([]);
    const [open, setOpen] = React.useState(false);
    const [pg, setpg] = React.useState(0);
    const [rpg, setrpg] = React.useState(5);
    const [ticketBookingList, setTicketBookingList] = useState([]);
    // const [state, setState] = React.useState({
    //     checkedA: true,
    //     checkedB: true,
    //     checkedF: true,
    //     checkedG: true,
    // });
    const [configuration, setConfiguration] = useState({
        parkId:'',
        mid: '',
        workingKey: '',
        accessKey: '',
    });

    const validationSchema = Yup.object().shape({
        parkId: Yup.string().required('park name is required'),
        mid: Yup.string().required('mid is required'),
        workingKey: Yup.string().required('working Key is required'),
        accessKey: Yup.string('access Key is required'),

    });
    const handleChangePage = (event, newpage) => {
        setpg(newpage);
    }

    useEffect(() => {
        getConfigurationList();
        getParkList();

        return () => {
            setConfigurationList([]);
            setParkList([]);
        }
    }, []);
    const getParkList = () => {
        const userDetails = JSON.parse(localStorage.getItem("userDetail"));
        console.log(userDetails.role)

        const result = userDetails.role === "admin" ? null : userDetails.profileId;
        ParkService.addAllProfileId({ profileRegistrationId: result }).then((res) => {
            setParkList(res);

        }).catch((err) => {
            // setError(err.message);
        });
    }
    const getConfigurationList = () => {
        const userDetails = JSON.parse(localStorage.getItem("userDetail"));
        console.log(userDetails.role)
        
           const result = userDetails.role==="admin" ? null:userDetails.profileId;
        ConfigurationService.getConfiguration({profileRegistrationId:result }).then((res) => {
            setConfigurationList(res);
        }).catch((err) => {
            // setError(err.message);
        });
    }
    const handleOpen = () => {
        setOpen(true);
    };
    const onclick = () => {
        setOpen(true);
    }
    const handleClose = () => {
        setOpen(false);
    };
    const onSubmit = data => {
        console.log(JSON.stringify(data, null, 2));
    };
    const editConfiguration = (configuration) => {
        setConfiguration(configuration);
        setOpen(true);
    }

    const deleteConfiguration = (configurationdelete) => {
        if (configurationdelete) {
            ConfigurationService.deleteConfiguration(configurationdelete).then((res) => {
                //getParkList();
                getConfigurationList();
                onSubmit()
            }).catch((err) => {
            });
        }
    };
    const handleChangeRowsPerPage = (event) => {
        setrpg(parseInt(event.target.value, 10));
        setpg(0);
    }
    const formik = useFormik({
        initialValues: configuration,
        enableReinitialize: true,
        validationSchema: validationSchema,
        onSubmit: (values, { resetForm }) => {
            const userDetails = JSON.parse(localStorage.getItem("userDetail"));
            values.profileRegistrationId = userDetails.profileId;
            if (configuration._id) {
                ConfigurationService.upadeConfiguration(values).then((res) => {
                    handleClose();
                    //  getParkList();
                    getConfigurationList()
                    resetForm()
                    alert("Configuration Updated Successfully.");
                }).catch((err) => {
                });
            }
            else {
                ConfigurationService.creteConfiguration(values).then((res) => {
                    getConfigurationList();

                    resetForm();
                    handleClose();
                    alert(" Configuration Added Successfully.");
                    // props.history.push('/app/vendor');
                })
                    .catch((err) => {
                        alert(err.response.data.message)
                    })
            }

        },
    });
    return (
        <>

            <PageTitle title="Configuration" button={<Button
                variant="contained" onClick={handleOpen}
                size="medium"
                color="secondary" style={{ backgroundColor: '#ff6b81' }}> Add Configuration
            </Button>} />
            <Grid container spacing={4}>
                <Grid item xs={12}>
                    <Widget title="" upperTitle noBodyPadding bodyClass={classes.tableOverflow}>
                        <Table className="mb-0">
                            <TableHead >
                                <TableRow>
                                    {tableHeaders.map(key => (
                                        <StyledTableCell key={key}>{key}</StyledTableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {configurationList.slice(pg * rpg, pg * rpg + rpg).map((configuration) => (
                                    <TableRow key={configuration._id}>
                                           <TableCell className="pl-3 fw-normal" >{configuration.parkId?.parkName}</TableCell>
                                        <TableCell className="pl-3 fw-normal" >{configuration.mid}</TableCell>
                                        <TableCell className="pl-3 fw-normal" >{configuration.accessKey}</TableCell>
                                        <TableCell className="pl-3 fw-normal" >{configuration.workingKey}</TableCell>
                                        {/* <TableCell>
                                            <EditIcon style={{ cursor: 'pointer' }} onClick={() => editPark(park)} >
                                            </EditIcon >
                                        </TableCell> */}
                                        {/* <TableCell>
                                            <DeleteIcon style={{ cursor: 'pointer' }} onClick={() => deleteConfiguration(configuration)} />
                                        </TableCell> */}

                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                        <TablePagination
                            component="div"
                            rowsPerPageOptions={[5, 100, 1000]}
                            count={configurationList.length}
                            page={pg}
                            onPageChange={handleChangePage}
                            rowsPerPage={rpg}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Widget>
                </Grid>
            </Grid>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Add Configuration</DialogTitle>
                <form onSubmit={formik.handleSubmit} >
                    <DialogContent style={{ width: 308 }}>
                       
                        
                        <FormControl className={classes.formControl} style={{width:"258px"}}
                                        >
                                            <InputLabel id="demo-simple-select-label">Select Park</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                name="parkId"
                                                label="Select Park"
                                               
                                             onChange={e => { formik.handleChange(e); }}
                                             value={formik.values.parkId}
                                             error={formik.touched.parkId && Boolean(formik.errors.parkId)}
                                             helperText={formik.touched.parkId && formik.errors.parkId}
                                            >
                                                <MenuItem value="">
                                                    <em>None</em>
                                                </MenuItem>

                                                {parkList.map((parkDet) => (
                                                    <MenuItem key={parkDet._id} value={parkDet._id}>{parkDet.parkName}

                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                       
                            
                                <TextField
                                    style={{ width: 258 }}
                                    id="mid"
                                    name="mid"
                                    label="MID"
                                    type="text"
                                    onChange={formik.handleChange}
                                    value={formik.values.mid}
                                    error={formik.touched.mid && Boolean(formik.errors.mid)}
                                    helperText={formik.touched.mid && formik.errors.mid}
                                />
                          
                           
                                <TextField
                                    style={{ width: 258 }}
                                    id="accessKey"
                                    name="accessKey"
                                    label="Access Key"
                                    type="text"
                                    onChange={formik.handleChange}
                                    value={formik.values.accessKey}
                                    error={formik.touched.accessKey && Boolean(formik.errors.accessKey)}
                                    helperText={formik.touched.accessKey && formik.errors.accessKey}
                                />
                           
                            
                                <TextField
                                    style={{ width: 258 }}
                                    id="workingKey"
                                    name="workingKey"
                                    label="Working Key"
                                    type="text"
                                    onChange={formik.handleChange}
                                    value={formik.values.workingKey}
                                    error={formik.touched.workingKey && Boolean(formik.errors.workingKey)}
                                    helperText={formik.touched.workingKey && formik.errors.workingKey}
                                />
                          
                        


                    </DialogContent>
                    <DialogActions>
                        
                        <Button onClick={handleClose}>Cancel</Button>
                        <Button type="submit" >Add</Button>
                    </DialogActions>
                </form>
            </Dialog>
        </>
    );

}


