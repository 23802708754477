import React from "react";
import {
  Route,
  Switch,
  Redirect,
  withRouter,
} from "react-router-dom";
import classnames from "classnames";
import BookingReport from "../../pages/dashboard/BookingReport"
import CounterDetails from "../../pages/dashboard/CounterDetails"
import Report from "../../pages/dashboard/report"
import ReportSearch from "../../pages/dashboard/reportssearch"
import Success from "../../pages/dashboard/Success"
import ProfileRegistration from "../../pages/dashboard/ProfileRegistration"
import Privacy from "../../pages/dashboard/Privacy"
import Coaching from "../../pages/dashboard/Coaching"
import Park from "../../pages/dashboard/Park"
import User from "../../pages/dashboard/User"

import Advatagement from "../../pages/dashboard/Advatagement"
import Exbhition from "../../pages/dashboard/Exbhition"
import Specilization from "../../pages/dashboard/Specilization"
import Doctor from "../../pages/dashboard/Doctor"
import Clinic from "../../pages/dashboard/Clinic"
import Branch from "../../pages/dashboard/branch"
// import CounterLogin from "../../pages/dashboard/counterlogin"
import DoctorReport from "../../pages/dashboard/DoctorReport"
import Configuration from "../../pages/dashboard/Configuration"
import {Box, IconButton, Link} from '@material-ui/core'
import Icon from '@mdi/react'
import {
  mdiFacebook as FacebookIcon,
  mdiTwitter as TwitterIcon,
  mdiGithub as GithubIcon,
} from '@mdi/js'

import useStyles from "./styles";

import Header from "../Header";
import Sidebar from "../Sidebar";

// pages
// import Dashboard from "../../pages/dashboard/SchoolRegistration";
import Typography from "../../pages/typography";
import Notifications from "../../pages/notifications";
import Maps from "../../pages/maps";
import Icons from "../../pages/icons";
import Charts from "../../pages/charts";
// context
import { useLayoutState } from "../../context/LayoutContext";
import {useUserState} from "../../context/LayoutContext";
import { Category } from "@material-ui/icons";

function Layout(props) {
  var classes = useStyles();

  // global
  var layoutState = useLayoutState();

  return (
    <div className={classes.root}>
        <>
          <Header history={props.history} />
          <Sidebar />
          <div
            className={classnames(classes.content, {
              [classes.contentShift]: layoutState.isSidebarOpened,
            })}
          >
            <div className={classes.fakeToolbar} />
            <Switch>
            <Route path="/app/bookingreport" component={BookingReport} />
            <Route path="/app/counterdetails" component={CounterDetails} />
            <Route path="/app/report" component={Report} />
            <Route path="/app/searchreports" component={ReportSearch} />
            <Route path="/app/profileregistration" component={ProfileRegistration} />
            <Route path="/app/reportofdoctor" component={DoctorReport} />
            <Route path="/app/success" component={Success} />
              <Route path="/app/park" component={Park} />
              <Route path="/app/user" component={User} />

              <Route path="/app/advatagement" component={Advatagement} />
              <Route path="/app/exbhition" component={Exbhition} />
              <Route path="/app/specilization" component={Specilization} />
              <Route path="/app/doctor" component={Doctor} />
              <Route path="/app/clinic" component={Clinic} />
              <Route path="/app/branch" component={Branch} />
              {/* <Route path="/app/counterlogin" component={CounterLogin} /> */}
              <Route path="/app/configuration" component={Configuration} />
              <Route path="/app/coaching" component={Coaching} />
              <Route path="/app/typography" component={Typography} />
              <Route path="/app/notifications" component={Notifications} />
              <Route
                exact
                path="/app/ui"
                render={() => <Redirect to="/app/ui/icons" />}
              />
              <Route path="/app/ui/reportofactivities" component={Icons} />
              <Route path="/app/ui/charts" component={Charts} />
            </Switch>
            <Box
              mt={5}
              width={"100%"}
              display={"flex"}
              alignItems={"center"}
              justifyContent="space-between"
            >
              {/* <div>
                <Link
                  color={'primary'}
                  href={'https://flatlogic.com/'}
                  target={'_blank'}
                  className={classes.link}
                >
                  Flatlogic
                </Link>
                <Link
                  color={'primary'}
                  href={'https://flatlogic.com/about'}
                  target={'_blank'}
                  className={classes.link}
                >
                  About Us
                </Link>
                <Link
                  color={'primary'}
                  href={'https://flatlogic.com/blog'}
                  target={'_blank'}
                  className={classes.link}
                >
                  Blog
                </Link>
              </div> */}
              {/* <div>
                <Link
                  href={'https://www.facebook.com/flatlogic'}
                  target={'_blank'}
                >
                  <IconButton aria-label="facebook">
                    <Icon
                      path={FacebookIcon}
                      size={1}
                      color="#6E6E6E99"
                    />
                  </IconButton>
                </Link>
                <Link
                  href={'https://twitter.com/flatlogic'}
                  target={'_blank'}
                >
                  <IconButton aria-label="twitter">
                    <Icon
                      path={TwitterIcon}
                      size={1}
                      color="#6E6E6E99"
                    />
                  </IconButton>
                </Link>
                <Link
                  href={'https://github.com/flatlogic'}
                  target={'_blank'}
                >
                  <IconButton
                    aria-label="github"
                    style={{marginRight: -12}}
                  >
                    <Icon
                      path={GithubIcon}
                      size={1}
                      color="#6E6E6E99"
                    />
                  </IconButton>
                </Link>
              </div> */}
            </Box>
          </div>
        </>
    </div>
  );
}

export default withRouter(Layout);
