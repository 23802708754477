import React, { useState } from "react";
import {
    Button, Dialog, DialogActions, DialogContent, DialogTitle, TableRow, Table, FormControl,
    TableHead, TableBody, InputLabel, Select, MenuItem, TableCell, Popper, Box,
} from "@material-ui/core";

import * as Yup from 'yup';
import { Grid, TextField } from "@material-ui/core";
import { Alert, AlertTitle } from '@material-ui/lab';
import { useFormik } from 'formik';
import ParkService from "./Locality/Service/parkService";

import { useEffect } from 'react';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import Widget from "../../components/Widget/Widget";
import PageTitle from "../../components/PageTitle/PageTitle";
import { withStyles, makeStyles } from '@material-ui/core/styles';
import UserService from "./Locality/Service/userService";
import DialogContentText from '@material-ui/core/DialogContentText';
// import 'date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDatePicker,
} from '@material-ui/pickers';

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: "rgb(255, 107, 129)",
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);
const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 200,
    },
}));
export default function User() {
    const tableHeaders = ['Park','Name', 'Email', 'Phone', 'Status','Edit', 'Delete'];

    const classes = useStyles();
    const [userList, setUserList] = useState([]);
    const [age, setAge] = React.useState('');
    // const [branchIdList, setBranchIdList] = useState([]);
    const [open, setOpen] = React.useState(false);
    const [open1, setOpen1] = React.useState(false);
 const [parkList, setParkList] = useState([]);
     const [qrCode, setQrCode] = useState([]);
    const [anchorEl, setAnchorEl] = useState([]);
    const [user, setUser] = useState({
        name: '',
        email: '',
        phone: '',
        password:'',
        status:'',
        parkId:'',
    })
    const [formValues, setFormValues] = useState([{ excutiveOfficerMobile: "", excutiveOfficerName: "",counterNumber:''  }]);

    const validationSchema = Yup.object().shape({
        name: Yup.string().required(' Name is required'),
        email: Yup.string().required('email is required'),
        phone: Yup.string().required('phone is required'),
        password:Yup.string().required('password is required'),
        status:Yup.string().required('status is required'),
                parkId: Yup.string().required('park name is required'),
        
    });

    useEffect(() => {
        getParkList();
        getUserList();
        return () => {
            setUserList([]);
            setParkList([]);
        }
    }, []);
    const handleOpen = (status) => {
        if(status){
            setUser({
                name: '',
                email: '',
                phone: '',
                password:'',
                status:'',
                parkId:'',
        })
        }
        setOpen(true);
    };
    // const handleOpen = () => {
    //     setOpen(true);
    // };
    const handleOpen1 = () => {
        setOpen1(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    const handleClose1 = () => {
        setOpen1(false);
    };
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClickOpen1 = () => {
        setOpen1(true);
    };

    const handleChange = (i, e) => {
        const newFormValues = [...formValues];
        newFormValues[i][e.target.name] = e.target.value;
        setFormValues(newFormValues);
    };
    const addFormFields = () => {
        setFormValues([...formValues, { excutiveOfficerMobile: "", excutiveOfficerName: "",counterNumber:'' }]);
        console.log(formValues);
    };

    const removeFormFields = (i) => {
        const newFormValues = [...formValues];
        if (i !== 0) {
            newFormValues.splice(i, 1);
            setFormValues(newFormValues);
        } else if (i === 0 && newFormValues.length === 1) {
            setFormValues([{ type: "", name: "" }]);
        } else if (i === 0 && newFormValues.length > 1) {
            newFormValues.shift();
            setFormValues(newFormValues);
        }
    };
    const getParkList = () => {
        const userDetails = JSON.parse(localStorage.getItem("userDetail"));
        console.log(userDetails.role)

        const result = userDetails.role === "admin" ? null : userDetails.profileId;
        ParkService.addAllProfileId({ profileRegistrationId: result }).then((res) => {
            setParkList(res);

        }).catch((err) => {
            // setError(err.message);
        });
    }
  
    const getUserList = () => {
        const userDetails = JSON.parse(localStorage.getItem("userDetail"));


        const result = userDetails.role === "admin" ? null : userDetails.profileId;
        UserService.getUser({ profileRegistrationId: result }).then((res) => {
            setUserList(res);
        }).catch((err) => {
            // setError(err.message);
        });
    }
    // const getProfileId = () => {
    //     const userDetails = JSON.parse(localStorage.getItem("userDetail"));
    //     const result = userDetails.role === "admin" ? null : userDetails.profileId;
    //     BranchService.addAllProfileId({ profileRegistrationId: result }).then((res) => {
    //         setBranchList(res);

    //     }).catch((err) => {
            
    //     });
    // }

    const editUser = (user) => {
        
        setUser(user);
        handleOpen();
    }
    const deleteUser = (userdelete) => {
        if (userdelete) {
            UserService.deleteUser(userdelete).then((res) => {
                // getClinicList();
                // getProfileId()
                getUserList()
            }).catch((err) => {
            });
        }
    };
  
    const formik = useFormik({
        initialValues: user,
        enableReinitialize: true,
        validationSchema: validationSchema,
        onSubmit: (values, { resetForm }) => {
            const userDetails = JSON.parse(localStorage.getItem("userDetail"));
            values.profileRegistrationId = userDetails.profileId;
            if (user._id) {
            UserService.upadeUser(values).then((res) => {
                handleClose();
                getUserList();
                resetForm()
                alert(" User Updated Successfully.");
              })
                .catch((err) => {
                  alert(err.response.data.message)
                })
            }
            else {
                UserService.registerWithProvider(values).then((res) => {
                        //getDoctorList();
                        getUserList()
                        resetForm();
                        handleClose();
                        alert(" User Added Successfully.");
                        // props.history.push('/app/vendor');
                    })
                        .catch((err) => {
                            alert(err.response.data.message)
                        })
                }
        },
    });

    return (

        <>
            <PageTitle title="User" button={<Button
                variant="contained" onClick={handleOpen}
                size="medium"
                color="secondary" style={{ backgroundColor: '#ff6b81' }}> Add User
            </Button>} />
            <Grid container spacing={4}>
                <Grid item xs={12}>
                    <Widget title="" upperTitle noBodyPadding bodyClass={classes.tableOverflow}>

                        <Table className="mb-0">
                            <TableHead >
                                <TableRow>
                                    {tableHeaders.map(key => (
                                        <StyledTableCell key={key}>{key}</StyledTableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {userList.map((user) => (

                                    <TableRow key={user._id}>
                                        <TableCell className="pl-3 fw-normal" >{user.parkId?.parkName}</TableCell>
                                        
                                        <TableCell className="pl-3 fw-normal" >{user.name}</TableCell>
                                        <TableCell className="pl-3 fw-normal" >{user.email}</TableCell>
                                        <TableCell className="pl-3 fw-normal" >{user.phone}</TableCell>
                                        <TableCell className="pl-3 fw-normal" >{user.status?"Active":"In Active"}</TableCell>
                                        <TableCell>
                                            <EditIcon style={{ cursor: 'pointer' }} onClick={() => editUser(user)} >
                                            </EditIcon >
                                        </TableCell>
                                        <TableCell>
                                            <DeleteIcon style={{ cursor: 'pointer' }} onClick={() => deleteUser(user)} />
                                        </TableCell>

                                      
                        

                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                        {/* {branchUserList.map(({ _id, cityName }) => (
                  <MenuItem key={_id} value={_id}>{cityName}</MenuItem>
                ))} */}
                        
                    </Widget>
                </Grid>
            </Grid>
           
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Add User</DialogTitle>
                <form onSubmit={formik.handleSubmit} >
                    <DialogContent style={{ width: 408 }}>
                        <FormControl className={classes.formControl} style={{width:"258px"}}
                                                                >
                                                                    <InputLabel id="demo-simple-select-label">Select Park</InputLabel>
                                                                    <Select
                                                                        labelId="demo-simple-select-label"
                                                                        id="demo-simple-select"
                                                                        name="parkId"
                                                                        label="Select Park"
                                                                       
                                                                     onChange={e => { formik.handleChange(e); }}
                                                                     value={formik.values.parkId}
                                                                     error={formik.touched.parkId && Boolean(formik.errors.parkId)}
                                                                     helperText={formik.touched.parkId && formik.errors.parkId}
                                                                    >
                                                                        <MenuItem value="">
                                                                            <em>None</em>
                                                                        </MenuItem>
                        
                                                                        {parkList.map((parkDet) => (
                                                                            <MenuItem key={parkDet._id} value={parkDet._id}>{parkDet.parkName}
                        
                                                                            </MenuItem>
                                                                        ))}
                                                                    </Select>
                                                                </FormControl>
                        <TextField
                            InputProps={{ style: { width: 258 } }}
                            autoFocus
                            margin="dense"
                            id="name"
                            name="name"
                            label="Name"
                            type="text"
                            variant="standard"
                            value={formik.values.name}
                            onChange={formik.handleChange}
                            error={formik.touched.name && Boolean(formik.errors.name)}
                            helperText={formik.touched.name && formik.errors.name}
                        />
                        <TextField
                            InputProps={{ style: { width: 258 } }}
                            autoFocus
                            margin="dense"
                            id="email"
                            name="email"
                            label="email"
                            type="text"
                            variant="standard"
                            value={formik.values.email}
                            // value={formik.values.branchName}
                            onChange={formik.handleChange}
                            error={formik.touched.email && Boolean(formik.errors.email)}
                            helperText={formik.touched.email && formik.errors.email}
                        />
                        <TextField
                            InputProps={{ style: { width: 258 } }}

                            margin="dense"
                            id="phone"
                            name="phone"
                            label="Contact Number"
                            type="text"
                            variant="standard"
                            value={formik.values.phone}
                            onChange={formik.handleChange}
                            error={formik.touched.phone && Boolean(formik.errors.phone)}
                            helperText={formik.touched.phone && formik.errors.phone}
                        />
                         <TextField
                            InputProps={{ style: { width: 258 } }}

                            margin="dense"
                            id="password"
                            name="password"
                            label="password"
                            type="password"
                            variant="standard"
                            value={formik.values.password}
                            onChange={formik.handleChange}
                            error={formik.touched.password && Boolean(formik.errors.password)}
                            helperText={formik.touched.password && formik.errors.password}
                        />
                         <FormControl variant="standard" fullWidth="true"  style={{ width: 370}}>
                                      <InputLabel id="demo-simple-select-standard-label">Status</InputLabel>
                                      <Select
                                        labelId="demo-simple-select-standard-label"
                                        id="status"
                                        name="status"
                                        value={formik.values.status}
                                        label="Status"
                                        onChange={formik.handleChange}
                                        error={formik.touched.status && Boolean(formik.errors.status)}
                                        helperText={formik.touched.status && formik.errors.status}
                                      >
                                        <MenuItem value="">
                                          <em>None</em>
                                        </MenuItem>
                                        <MenuItem value={true}>Active</MenuItem>
                                        <MenuItem value={false}>In Active</MenuItem>
                        
                                      </Select>
                                    </FormControl>
                        <br></br>
                        
                    </DialogContent>

                    <DialogActions>
                        <Button onClick={handleClose}>Cancel</Button>
                        <Button type="submit">Add</Button>
                    </DialogActions>
                </form>
            </Dialog>
        </>
    );
}


