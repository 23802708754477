import requests from './httpService';
const UserService = {
  getAllUser() {
    return requests.get(`/user/list`);
  },
  getUser(body){
    return requests.post('/user/get',body); 
  },
  registerWithProvider(body){
    return requests.post('/user/userregister',body); 
  },
  creteUser(body){
    return requests.post('/user/userregister',body); 
  },
  deleteUser(body){
    return requests.delete(`/user/${body._id}`); 
    
  },
  upadeUser(body) {
    return requests.put(`/user/${body._id}`,body); 
  }, 
  addAllProfileId(body) {
    return requests.post(`/branch/listbyprofileid`,body);
  },
  getAppointmentDetailsById(body) {
    return requests.post(`/loanbooking/getqrcodebystatus`,body );
  },
};

export default UserService;
